import {
  currentCompany,
  currentUser,
  environment,
} from 'lib/document-helpers/document-meta';

export default function initializeGlobals() {
  /**
   * Resolve full path to assets that are loaded at runtime using Webpack's publicPath setting
   * @see https://webpack.js.org/configuration/output/#output-publicpath
   */
  const cdnHost = environment()?.AWS_STATIC_HOST;
  // eslint-disable-next-line camelcase, no-undef
  __webpack_public_path__ = cdnHost ? `${cdnHost}/packs/` : '/packs/';

  window.Workshop ||= {};
  window.Workshop.currentUser = currentUser();
  window.Workshop.currentCompany = currentCompany();
}
