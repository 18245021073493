import flatpickr from 'flatpickr';
import { isNumber } from 'lodash-es';
import { DateTime } from 'luxon';

// eslint-disable-next-line import/no-webpack-loader-syntax
import prevArrowIcon from '!!raw-loader!/app/assets/images/icons/icon-caret-left.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import nextArrowIcon from '!!raw-loader!/app/assets/images/icons/icon-caret-right.svg';
import CalendarIcon from '@workshop/baja/assets/icons/icon-cal.svg';
import CaretDownIcon from '@workshop/baja/assets/icons/icon-caret-down.svg';
import { roundDate } from 'utils/dates/round-date';

export const DateInputProps = {
  modes: ['single', 'range'],
  variants: ['default', 'filter'],
};

export default {
  name: 'DateInput',

  components: {
    CalendarIcon,
    CaretDownIcon,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
    dateFormat: {
      type: String,
      default: undefined,
    },
    enableTime: {
      type: Boolean,
      default: false,
    },
    minuteIncrement: {
      type: Number,
      default: undefined,
    },
    minDate: {
      type: [String, Date],
      default: undefined,
    },
    maxDate: {
      type: [String, Date],
      default: undefined,
    },
    mode: {
      type: String,
      default: 'single',
      validator: (val) => DateInputProps.modes.includes(val),
    },
    static: {
      type: Boolean,
      default: false,
    },
    useCompanyTimeZone: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (val) => DateInputProps.variants.includes(val),
    },
  },

  data() {
    return {
      inputValue: '',
      config: {
        mode: this.mode,
        altInput: true,
        // This is only used to differentiate if we're formatting
        // the hidden input or the altInput below in the formatDate function
        dateFormat: 'Z',
        defaultDate: null,
        altFormat: this.getAltFormat(),
        enableTime: this.enableTime,
        minuteIncrement: this.minuteIncrement || 1,
        position: this.variant === 'filter' ? 'auto right' : 'auto left',
        monthSelectorType: 'static',
        nextArrow: nextArrowIcon,
        prevArrow: prevArrowIcon,
        minDate: this.minDate,
        maxDate: this.maxDate,
        static: this.static,
        locale: { rangeSeparator: ' - ' },

        // How the date appears to the user
        formatDate: (date, format) => {
          // If date is removed, return empty string
          // to clear form data value
          if (!date) {
            return '';
          }

          // Create a DateTime object from the JS Date we get
          // either from parseDate, or from flatpickr upon date selection
          // so that we can set it manually to the company timezone.

          // The Luxon keepLocalTime option is important here:
          // https://moment.github.io/luxon/api-docs/index.html#datetimesetzone
          const newDateTime = DateTime.fromJSDate(date).setZone(
            this.currentCompany.timeZone,
            { keepLocalTime: true }
          );

          // We can use an object of options to format our date,
          // in which case we want to use 'toLocaleString', per Luxon:
          // https://moment.github.io/luxon/#/formatting?id=intldatetimeformat
          if (typeof format === 'object') {
            return newDateTime.toLocaleString(format);
          }

          // We can also pass a string of formatting tokens,
          // In which case we want to use toFormat, per Luxon:
          // https://moment.github.io/luxon/#/formatting?id=formatting-with-tokens-strings-for-cthulhu
          return newDateTime.toFormat(format);
        },

        // Incoming dates
        parseDate: (dateString) => {
          // The Luxon keepLocalTime option is important here:
          // https://moment.github.io/luxon/api-docs/index.html#datetimesetzone
          return DateTime.fromISO(dateString)
            .setZone(this.currentCompany.timeZone)
            .setZone('local', { keepLocalTime: true })
            .toJSDate();
        },

        // Events
        onChange: [
          (selectedDates) => {
            // selectedDates is an array even when you're selecting a single date
            this.onChangeHandler(selectedDates);
          },
        ],

        onValueUpdate: [
          (selectedDates) => {
            // onChange doesn't seem to trigger reliably when selecting times,
            // or when using the keyboard, but this event does - so we'll also
            // call the onChange handler here for safety
            this.onChangeHandler(selectedDates);
          },
        ],

        onClose: [
          (selectedDates) => {
            // If we're in range mode, and two dates aren't selected on close,
            // and we have initial dates, just set the calendar back to those dates.
            if (
              this.mode === 'range' &&
              selectedDates.length < 2 &&
              Array.isArray(this.config.defaultDate)
            ) {
              this.$nextTick(() => {
                this.calendar.setDate(this.config.defaultDate);
              });
              // If we're in range mode, and two dates aren't selected on close,
              // but we don't have a initial dates, just clear the calendar altogether.
            } else if (this.mode === 'range' && selectedDates.length < 2) {
              this.$nextTick(() => {
                this.calendar.clear();
              });
            }
          },
        ],
      },
    };
  },

  computed: {
    disabled() {
      return this.context.attributes.disabled;
    },
  },

  watch: {
    dateFormat(newVal) {
      this.calendar.set('altFormat', !newVal ? this.getAltFormat() : newVal);
    },

    enableTime(newVal) {
      this.config.enableTime = newVal;
      this.createCalendar();
    },

    minDate(newVal) {
      this.calendar.set('minDate', newVal);
    },

    maxDate(newVal) {
      this.calendar.set('maxDate', newVal);
    },

    mode(newVal) {
      this.calendar.set('mode', newVal);
    },

    static(newVal) {
      this.calendar.set('static', newVal);
    },

    // Watch for model changes and explicitly set the date in the calendar
    'context.model': {
      immediate: true,
      handler(newVal, oldVal) {
        if (Array.isArray(newVal)) {
          this.config.defaultDate = this.getDefaultDate(newVal);
        } else {
          this.inputValue = newVal;
        }

        if (this.calendar && !oldVal) {
          this.calendar.setDate(newVal);
        }
      },
    },

    // Watch for disabled attribute changes and set disabled on the altInput specifically
    'context.attributes.disabled': {
      handler(newVal) {
        if (this.calendar) {
          this.calendar.altInput.disabled = newVal;
        }
      },
    },

    'context.label': {
      handler(newVal) {
        if (this.calendar) {
          this.calendar.altInput.setAttribute('aria-label', newVal);
        }
      },
    },
  },

  mounted() {
    this.createCalendar();
  },

  methods: {
    getAltFormat() {
      let altFormat = '';
      // If a dateFormat is provided, use that.
      if (this.dateFormat) {
        altFormat = this.dateFormat;
        // Otherwise if enableTime is true, lets use a format that shows the time and timezone
      } else if (this.enableTime) {
        altFormat = {
          ...DateTime.DATETIME_SHORT,
          timeZoneName: 'short',
        };
      } else {
        // Or just use the most common date format used in the app
        altFormat = 'L/d/yyyy';
      }
      return altFormat;
    },

    getDefaultDate(dates) {
      const startingAtDate = dates[0];
      let endingAtDate = dates[1];

      if (this.minDate > startingAtDate) {
        this.config.minDate = startingAtDate;
      }
      if (endingAtDate < startingAtDate) {
        endingAtDate = startingAtDate;
      }
      return [startingAtDate, endingAtDate];
    },

    createCalendar() {
      if (this.calendar) {
        this.calendar.destroy();
      }

      this.calendar = flatpickr(this.$refs.dateInput, this.config);
      this.calendar.altInput.setAttribute('aria-label', this.context.label);
    },

    convertDate(date) {
      let dateTime = DateTime.fromJSDate(date);

      // if minuteIncrement is set, enforce rounding to the nearest increment
      if (isNumber(this.minuteIncrement)) {
        dateTime = roundDate(dateTime, 1000 * 60 * this.minuteIncrement);
      }

      dateTime = dateTime.setZone(this.currentCompany.timeZone, {
        keepLocalTime: true,
      });

      if (!this.useCompanyTimeZone) {
        dateTime = dateTime.toUTC();
      }

      return dateTime.toISO();
    },

    onChangeHandler(selectedDates) {
      // convert the selection using convertDate
      // if convertDate rounded the value, update the input to reflect this
      let selection = null;
      if (selectedDates.length > 1) {
        const startingAt = this.convertDate(selectedDates[0]);
        const endingAt = this.convertDate(selectedDates[1]);

        selection = {
          startingAt,
          endingAt,
        };

        if (
          new Date(startingAt).getTime() !== selectedDates[0].getTime() ||
          new Date(endingAt).getTime() !== selectedDates[1].getTime()
        ) {
          this.calendar.setDate([startingAt, endingAt]);
        }
      } else if (selectedDates.length === 1) {
        selection = this.convertDate(selectedDates[0]);

        if (new Date(selection).getTime() !== selectedDates[0].getTime()) {
          this.calendar.setDate(selection);
        }
      }

      // update the model
      this.$set(this.context, 'model', selection);
      this.$emit('change', selection);
    },
  },
};
